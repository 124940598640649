<template>
  <div
    class="bg-neutral-black text-neutral-white py-sm positive-padding flex items-center justify-start"
  >
    <div
      class="text-light-7 flex flex-col gap-0 self-center tracking-wide md:flex-row md:gap-1"
    >
      {{ $ts('footer.copyrightNew', { year: new Date().getFullYear() }) }}
      -
      {{ $ts('footer.copyright.allRightsReserved') }}
    </div>
  </div>
</template>
