<script setup lang="ts">
import type { CookieRef } from '#app'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'
import type { HeaderActionCartProps } from './HeaderActionCart.props'

defineProps<HeaderActionCartProps>()

const { breakpoint } = useProjectBreakpoint()
const { openDialog, closeAllDialogs } = useDialog()
const { cleanMenuStatus } = useMenu()
const { cartUrl, getCanonicalUrl } = useRouteHelper()
const { dispatchGlobalNavigationEvent } = useGANavigationEvents()

const { country, countryLocale } = useCountryLocale()
// get Cart Items
const { cart } = useCart()

const isFederatedApp = computed(() => useRuntimeConfig().public.isFederated)
const baseUrl = computed(() => useRuntimeConfig().public.baseUrl)

const handleButtonClick = async () => {
  if (isFederatedApp.value) {
    navigateTo(`${baseUrl.value}/${countryLocale.value}/cart`, {
      external: true,
    })
    return
  }
  dispatchGlobalNavigationEvent('cart')
  const isLg = breakpoint.lg.value
  if (isLg) {
    await Promise.all([closeAllDialogs(), cleanMenuStatus()]).then(() => {
      openDialog(SIDEBAR_IDS.headerSideCart)
    })
  } else {
    const path = getCanonicalUrl(cartUrl)
    navigateTo(path)
  }
}

const federatedCookieCart = (country: string): CookieRef<number> => {
  return useCookie(
    `federatedCookieCartLineItemsCount_${country.toUpperCase()}`,
    { default: () => ref(0), domain: '.armani.com' }
  )
}

const skusCount = computed(() => {
  if (!isFederatedApp.value) return cart.value?.skus_count

  return federatedCookieCart(country.value)
})
</script>

<template>
  <li>
    <button
      :aria-label="$ts('aria-labels.cart')"
      class="underline-effect-appear flex items-center justify-center p-2.5 lg:p-0"
      @click.prevent="handleButtonClick"
    >
      <span class="relative flex justify-center">
        <span class="text-effect pb-[0.0645rem]">
          <DefaultIconsCart class="shrink-0" aria-hidden="true" />
        </span>
        <div
          v-if="skusCount"
          class="badge bg-primitives-black text-primitives-white lg:bg-background-primary lg:text-text-secondary absolute left-3 top-[11px] h-3 w-3 shrink-0 rounded-full text-center text-[8px]"
          :class="counterClass ?? ''"
        >
          {{ skusCount }}
        </div>
      </span>
    </button>
  </li>
</template>
