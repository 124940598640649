<script setup lang="ts">
import type { HeaderActionAddToCartProps } from './HeaderActionAddToCart.props'
const props = defineProps<HeaderActionAddToCartProps>()
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

const { openDialog } = useDialog()
const { addToCart } = useMenu()

const handleClickMenu = async () => {
  if ((props.disabled || props.selectedExtOutOfStock) && !props.onlyStore) {
    return
  } else if (props.onlyStore) {
    openDialog(SIDEBAR_IDS.wrapperReserveInStore)
  } else {
    addToCart()
  }
}

const loaded = computed(() => {
  return (
    (typeof props.disabled !== 'undefined' ||
      typeof props.selectedExtOutOfStock !== 'undefined') &&
    typeof props.onlyStore !== 'undefined'
  )
})

const getButtonLabel = computed(() => {
  if ((props.disabled || props.selectedExtOutOfStock) && !props.onlyStore)
    return 'productCard.soldOut'

  if (props.onlyStore) return 'pdp.productDetails.informationSection.reserve'

  return 'addToCart'
})
</script>
<template>
  <li>
    <AtomsCta
      :icon-show-default="false"
      :full-width="true"
      class="whitespace-nowrap"
      data-testid="mobile-add-to-cart"
      :loading="!loaded"
      @click-handler="handleClickMenu"
    >
      <template v-if="!!loaded" #label>
        {{ $ts(getButtonLabel) }}
      </template>
      <template v-else #icon>
        <!-- keep this empty for showing the loader -->
      </template>
    </AtomsCta>
  </li>
</template>
