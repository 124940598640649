import type { HeaderCardType } from '@design-system/components/Header/HeaderListView.props'

export async function useImgLogoBottomBar(deliveryKey: string) {
  const localePath = useLocalePath()

  const query = {
    locale: localePath(''),
    vse: useRoute().query.vse,
    contentId: useRoute().query.contentId,
    deliveryKey: deliveryKey,
  }

  try {
    const { data: imgLogoContentResponse } = await useFetch(
      '/api/cms/getImgLogoBottomBar',
      {
        query,
      }
    )

    const imgLogoTransform: HeaderCardType[] =
      imgLogoContentResponse.value?.content.listOfContentItems ?? []

    return imgLogoTransform
  } catch {
    return null
  }
}
