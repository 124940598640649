<script lang="ts" setup>
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'
import type { HeaderCardType } from './HeaderListView.props'
const data = await useImgLogoBottomBar('layer-cross-brand')
const { isDialogOpen: isCartDialogOpen } = useDialog(SIDEBAR_IDS.headerSideCart)
</script>
<template>
  <HeaderListView
    :id="SIDEBAR_IDS.popupLayerListView"
    :cards="data as HeaderCardType[]"
  />
  <LoginSidebar />
  <LazyHeaderSideCart v-if="isCartDialogOpen" />
</template>
