<script setup lang="ts">
import type { HeaderHamburgerProps } from './HeaderHamburger.props'

const props = withDefaults(defineProps<HeaderHamburgerProps>(), {
  expanded: false,
})

const emit = defineEmits(['click-handler'])
</script>

<template>
  <button
    :aria-expanded="expanded"
    aria-haspopup="dialog"
    class="mobile-menu-toggler relative z-[1000] flex cursor-pointer items-center justify-center gap-2 p-2.5 lg:h-6 lg:p-0"
    :class="{ 'bg-active block': expanded }"
    :aria-label="$ts('accessibility.menu')"
    @click="emit('click-handler')"
  >
    <span
      class="lv-header-icon-burger__bars"
      :class="[
        props.color,
        {
          'dynamic-active': color && color === 'dynamic' && expanded,
        },
      ]"
    >
      <span />
    </span>
    <p
      class="text-book-6 underline-effect-appear hidden text-nowrap lg:inline-block"
      :class="[
        {
          '!hidden': expanded,
        },
      ]"
    >
      <span class="text-effect outline-none">
        {{ $ts('accessibility.menu') }}
      </span>
    </p>
  </button>
</template>

<style scoped lang="scss">
.lv-header-icon-burger__bars {
  @apply relative flex h-6 w-6 items-center;

  span {
    @apply absolute left-1/2 top-1/2 flex h-[1px] w-3 -translate-x-1/2 -translate-y-1/2 rounded-lg;
    background-color: currentColor;
    transition: background 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);

    &::after,
    &::before {
      @apply absolute block h-[1px] w-3 transform-gpu rounded-lg;
      content: '';
      background-color: currentColor;
      transition:
        transform 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),
        background 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    }

    &::before {
      transform: translateY(-5px);
    }

    &::after {
      transform: translateY(5px);
    }
  }

  &.black {
    span {
      @apply bg-neutral-black lg:bg-neutral-black;
      &::after,
      &::before {
        @apply bg-neutral-black lg:bg-neutral-black;
      }
    }
  }
  &.white {
    span {
      @apply bg-neutral-black lg:bg-neutral-white;
      &::after,
      &::before {
        @apply bg-neutral-black lg:bg-neutral-white;
      }
    }
  }
  &.dynamic {
    span {
      @apply invert;
    }
  }
  &.dynamic-active {
    span {
      &::after,
      &::before {
        @apply bg-neutral-white;
      }
    }
  }
}

[aria-expanded='true'] {
  .lv-header-icon-burger__bars {
    span {
      background: transparent;
      transition-delay: 0.125s;
      &::before {
        transform: rotate(-45deg);
        transition-delay: 0.125s;
      }

      &::after {
        transform: rotate(45deg);
        transition-delay: 0.125s;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .bg-active {
    border-radius: 50%;
    background-color: rgb(242 242 242 / var(--tw-bg-opacity));
    height: 44px;
  }
}
</style>
