<script setup lang="ts">
const { currentWebsite, isGlobalWebsite } = useWebsite()
const { homeBrandPath } = useRouteHelper()
</script>

<template>
  <NuxtLink
    v-if="!isGlobalWebsite"
    :to="homeBrandPath"
    :aria-label="`${$ts('accessibility.goTo')} ${$ts(`accessibility.sites.${currentWebsite}`)}`"
    class="!flex items-center justify-between gap-6"
  >
    <component
      :is="logoMap[currentWebsite]"
      class="h-5 w-auto"
      aria-hidden="true"
    />
    <component
      :is="menuLogoMap[currentWebsite]"
      class="h-8 w-8"
      aria-hidden="true"
    />
  </NuxtLink>
</template>
