<script lang="ts" setup>
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'
const { openDialog } = useDialog()

const { getCountryName, getLanguageName } = useCountryHelper()
const { country, language } = useRouteHelper()
const { getCurrencySymbolFromCountryCode } = useCurrency()
const { dispatchActionChooseCountryEvent } = useGAActionEvents()
const title = SIDEBAR_IDS.footerAsideLanguage
const currency = getCurrencySymbolFromCountryCode(country)
const label = `${getCountryName(country)} ${currency ? `(${currency.code})` : ''} | ${getLanguageName(language)}`

const handleOpenDialog = () => {
  dispatchActionChooseCountryEvent(getCountryName(country), language, 'start')
  openDialog(title)
}
</script>

<template>
  <AtomsCta
    anatomy="link"
    :aria-label="`${label} - ${$ts('footer.firstBlock.countrySelector.title')}`"
    underline-effect="appear"
    @click-handler="handleOpenDialog"
  >
    <template #label>
      <div class="!text-light-6">
        {{ label }}
      </div>
    </template>
    <template #icon>
      <DefaultIconsChevronRight aria-hidden="true" />
    </template>
  </AtomsCta>
</template>
