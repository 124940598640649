<script setup lang="ts">
import type { HeaderActionAccountProps } from './HeaderActionAccount.props'

const props = defineProps<HeaderActionAccountProps>()
defineEmits<{
  (e: 'click-handler'): void
}>()

const isLogged = ref(false)

onMounted(() => {
  isLogged.value = props.isUserLogged
})

watch(
  () => props.isUserLogged,
  newVal => {
    isLogged.value = newVal
  }
)
</script>
<template>
  <li>
    <button
      :aria-label="$ts('aria-labels.myAccount')"
      aria-haspopup="dialog"
      class="underline-effect-appear relative flex p-2.5 lg:p-0 lg:pb-[0.0645rem]"
      @click="$emit('click-handler')"
    >
      <span class="text-effect">
        <DefaultIconsAccountNoLog v-if="!isLogged" aria-hidden="true" />
        <DefaultIconsAccountLog v-if="isLogged" aria-hidden="true" />
      </span>
    </button>
  </li>
</template>
