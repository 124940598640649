<script setup lang="ts">
import type { HeaderLogoBrandProps } from './HeaderLogoBrand.props'

const props = defineProps<HeaderLogoBrandProps>()
const { homeBrandPath } = useRouteHelper()
const { ts } = useI18n()
const { getBrandName } = await useBrandsSettings()

const titleBrandName = computed(() => {
  if (props.currentBrand === 'global') {
    return capitalizeFirstLetter(ts('accessibility.homepage'))
  }
  return getBrandName(props.currentBrand)
})
</script>

<template>
  <div
    :class="[
      `header-logo header-logo--${currentBrand}`,
      {
        'header-logo--megalogo': megalogo,
        'header-logo--megalogo-expanded': megalogo && megalogoExpanded,
        'header-logo--no-transition': freezeAnimation,
      },
    ]"
  >
    <NuxtLink
      :to="homeBrandPath"
      :aria-label="`${$ts('accessibility.goTo')} ${titleBrandName}`"
    >
      <component
        :is="
          currentBrand === 'global'
            ? menuLogoMap['global']
            : logoMap[currentBrand as LogoType]
        "
        aria-hidden="true"
      />
    </NuxtLink>
  </div>
</template>

<style scoped lang="scss">
.header-logo {
  svg {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  &--megalogo {
    svg {
      position: absolute;
      inset: 0;
      top: 50%;
      transform: translateY(-50%);
      height: auto;
      transition:
        width 0.5s cubic-bezier(0.5, 0, 0, 1),
        top 0.5s cubic-bezier(0.5, 0, 0, 1),
        color 75ms cubic-bezier(0.5, 0, 0, 1);
      will-change: width, top, color;
      pointer-events: none;
    }

    &-expanded {
      svg {
        --firstSectionHeight: calc(
          95svh -
            (
              var(--marketing-strip-height) + var(--monogram-strip-height) +
                var(--header-default-height)
            )
        );

        width: 100%;
        top: calc(var(--firstSectionHeight) / 2);

        @screen lg {
          --firstSectionHeight: calc(
            95svh -
              (var(--marketing-strip-height) + var(--monogram-strip-height))
          );
        }
      }
    }

    &:not(.header-logo--megalogo-expanded) {
      a::after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        width: 100%;
        max-width: 440px;
      }
    }
  }

  &--no-transition {
    svg {
      transition-duration: 0s !important;
    }
  }

  &--ea7,
  &--armani-clubs {
    &.header-logo--megalogo-expanded {
      svg {
        width: 33.33%;
        @screen md {
          width: 20%;
        }
      }
    }
  }
}
</style>
