<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import { closedCountries } from '@design-system/configs/countries'
import type { SidebarFooterProps } from '../Organisms/Sidebar/OrganismsSidebarFooter.props'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

const { isDialogOpen, closeDialog } = useDialog(
  SIDEBAR_IDS.footerCountryNotFound
)
const { ts } = useI18n()
const submitted = ref(false)
const formValues = ref()
const { customer } = await useCustomer()

const closeSidebarHandler = () => {
  if (isDialogOpen) {
    closeDialog()
    submitted.value = false
  }
}

const countriesData = closedCountries
  .map(({ name }) => ({ label: name, value: name }))
  .sort((a, b) => a.label.localeCompare(b.label))

const footerProps = computed<SidebarFooterProps>(() => ({
  primaryText: ts('footer.firstBlock.send'),
}))

const getFormattedDate = () => {
  const date = new Date()
  const year = date.getUTCFullYear()
  const month = String(date.getUTCMonth() + 1).padStart(2, '0')
  const day = String(date.getUTCDate()).padStart(2, '0')
  const hours = String(date.getUTCHours()).padStart(2, '0')
  const minutes = String(date.getUTCMinutes()).padStart(2, '0')
  const seconds = String(date.getUTCSeconds()).padStart(2, '0')
  const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0')

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`
}

const submitHandler = async () => {
  await $fetch('/api/notListedCountry', {
    method: 'POST',
    body: {
      email: formValues.value.email,
      country: formValues.value.country,
      'request-date': getFormattedDate(),
    },
  })
  submitted.value = true
}
</script>
<template>
  <div>
    
<FormKitLazyProvider config-file="true">
<OrganismsSidebarSlide
      :id="SIDEBAR_IDS.footerCountryNotFound"
      force-global-theme
      :footer-props="!submitted ? footerProps : undefined"
      is-alpha-layer-transparent
      @sidebar-closed="closeSidebarHandler"
      @primary-click="submitHandler"
    >
      <template #body>
        <div v-if="!submitted">
          <div class="mb-xl mt-14 flex">
            <button @click="closeSidebarHandler">
              <DefaultIconsPrevious />
            </button>
            <div class="text-book-5 ml-md uppercase">
              {{ $ts('footer.firstBlock.countrySelector.title') }}
            </div>
          </div>
          <div class="text-light-6 mb-sm">
            {{ $ts('footer.firstBlock.locationNotFoundText') }}
          </div>
          <FormKit
            id="form-country-sideslide"
            v-model="formValues"
            type="form"
            :actions="false"
            :incomplete-message="false"
          >
            <InputsTextFields
              type="text"
              :label="$ts('myAccount.login.email')"
              name="email"
              :value="customer?.email ?? ''"
              :classes="{ outer: 'mb-sm' }"
              validation="required|email"
              :validation-messages="{
                required: $ts('generic.requiredEmail'),
                email: $ts('generic.invalidEmail'),
              }"
              autocomplete="email"
              validation-visibility="submit"
            />
            <InputsSelect
              id="country-select-not-found"
              :label="$ts('footer.firstBlock.location')"
              :classes="{ outer: 'mb-sm' }"
              name="country"
              :options="countriesData"
              validation="required"
              :validation-messages="{ required: $ts('fieldRequired') }"
            />
          </FormKit>
          <div class="text-book-6 bg-primitives-grey-50 px-sm py-xs mt-10">
            {{ $ts('footer.firstBlock.disclaimer') }}
          </div>
        </div>
        <div v-else class="mt-14">
          <div class="mb-xl flex justify-between">
            <div class="text-book-5 uppercase">
              {{ $ts('footer.firstBlock.countrySelector.title') }}
            </div>
            <button @click="closeSidebarHandler">
              <DefaultIconsClose />
            </button>
          </div>

          <div class="text-light-6">
            {{ $ts('footer.firstBlock.thankYou') }}
          </div>
        </div>
      </template>
    </OrganismsSidebarSlide>
</FormKitLazyProvider>

  </div>
</template>
