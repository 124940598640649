<script setup lang="ts">
import type { HeaderBtnProps } from './HeaderBtn.props'

const props = defineProps<HeaderBtnProps>()
const { toggleLevel, isLevelActive, cleanMenuStatus, hasOtherItemActive } =
  useMenu()

const subListId = props.item?.subList?.id || ''
const isExternalIcon =
  props.item.external &&
  !props.item.icon &&
  !hasOtherItemActive(subListId, props.panel)
const menuListItemClass = '!text-book-5 !items-center !justify-between'
</script>

<template>
  <div class="lv-megamenu-list__wrapper">
    <AtomsCta
      v-if="item.subList"
      anatomy="link"
      :class="menuListItemClass"
      :full-width="!hasOtherItemActive(subListId, panel)"
      :link-icon-bigger="true"
      :underline-effect="isLevelActive(subListId, panel) ? 'default' : 'appear'"
      :aria-expanded="isLevelActive(subListId, panel)"
      @click="toggleLevel(subListId, panel)"
    >
      <template #label>
        {{ item.label }}
      </template>
      <template #icon>
        <DefaultIconsChevronRight
          v-show="!hasOtherItemActive(subListId, panel)"
          aria-hidden="true"
          class="-z-10"
        />
      </template>
    </AtomsCta>
    <MoleculesLinkWrapper
      v-else
      anatomy="link"
      :to="item?.to"
      :external="item?.external"
      :target="item.external ? '_blank' : ''"
      :underline-effect="'appear'"
      :link-icon-bigger="true"
      :full-width="!isExternalIcon && !!item.icon"
      :class="menuListItemClass"
      :aria-label="item.label"
      @click="!item.external ? cleanMenuStatus() : null"
    >
      <template #label>
        {{ item.label }}
      </template>
      <template #iconLink>
        <DefaultIconsGo
          v-show="isExternalIcon"
          aria-hidden="true"
          class="text-primitives-grey-200 ml-0.5"
        />
        <!-- @vue-skip menuLogoMap is not auto importing-->
        <component
          :is="menuLogoMap[item.icon]"
          v-show="item.icon"
          aria-hidden="true"
          class="h-7 w-7"
        />
      </template>
    </MoleculesLinkWrapper>
  </div>
</template>
