<script setup lang="ts">
import type { HeaderActionSearchProps } from './HeaderActionSearch.props.js'

withDefaults(defineProps<HeaderActionSearchProps>(), {
  showLabel: true,
})

defineEmits(['click-handler'])

const { ts } = useI18n()
</script>
<template>
  <li class="list-none">
    <button
      class="button-search flex cursor-pointer items-center gap-2"
      :class="{ 'p-2.5 lg:p-0': !showLabel }"
      :aria-label="ts('search.label')"
      aria-haspopup="dialog"
      @click="$emit('click-handler')"
    >
      <DefaultIconsSearch aria-hidden="true" />
      <span
        v-if="showLabel"
        class="text-book-6 underline-effect-appear relative text-nowrap"
      >
        <span class="text-effect outline-none">
          {{ ts('search.label') }}
        </span>
      </span>
    </button>
  </li>
</template>
