<script setup lang="ts">
import type { List } from '@design-system/components/Header/Menu/HeaderMenuNav.props'

const { deferredCurrentWebsite, currentWebsite } = useWebsite()
const countryAndLanguage = computed(() => useRoute().fullPath.split('/')[1])

const { navigationBarStatus } = useMenu()
const {
  isNavigating,
  freezeAnimation,
  transparencyState: headerTransparency,
  megalogo: headerMegalogo,
  isPinned: headerIsPinned,
  setIsPinned,
} = useDefaultHeaderConfig()

const error = useError()

const menuListsUrl = computed(
  () => `/api/${currentWebsite.value}/${countryAndLanguage.value}`
)
const { data: menuLists } = await useFetch<List[]>(menuListsUrl)

// Deferred state: update header style when navigation is over
const deferredState = ref({
  hasMegalogo: headerMegalogo.value,
  isTransparent: headerTransparency.value.active,
  transparencyColor: headerTransparency.value.color,
  navigationBarAnatomy: navigationBarStatus.value?.anatomy,
})

const isFederatedApp = computed(() => useRuntimeConfig().public.isFederated)

watch(
  () => isNavigating.value,
  newValue => {
    if (!newValue) {
      deferredState.value.hasMegalogo = headerMegalogo.value
      deferredState.value.isTransparent = headerTransparency.value.active
      deferredState.value.transparencyColor = headerTransparency.value.color
      deferredState.value.navigationBarAnatomy =
        navigationBarStatus.value.anatomy
    }
  }
)
</script>

<template>
  <slot
    name="header"
    :current-brand="currentWebsite"
    :deferred-current-brand="deferredCurrentWebsite"
    :has-megalogo="error?.statusCode ? false : deferredState.hasMegalogo"
    :is-transparent="error?.statusCode ? false : deferredState?.isTransparent"
    :transparency-color-text="deferredState?.transparencyColor"
    :transparency-paused="headerTransparency?.paused"
    :is-pinned="headerIsPinned"
    :freeze-animation="freezeAnimation"
    :menu-lists="menuLists ?? []"
    :is-federated-app="!!isFederatedApp"
    @is-pinned-handler="setIsPinned"
  />
  <slot
    name="navigation"
    :anatomy="deferredState.navigationBarAnatomy"
    :is-federated-app="!!isFederatedApp"
  />
  <HeaderSidebars />
</template>
