<script setup lang="ts">
import type { HeaderDefaultProps } from './HeaderDefault.props'
const props = defineProps<HeaderDefaultProps>()
const headerContainer = ref<HTMLElement>()
const { height } = useElementSize(headerContainer)

watch(
  () => height.value,
  height => {
    if (document)
      document.documentElement.style.setProperty(
        '--header-default-height',
        `${height}px`
      )
  }
)

/**
 * CSS Classes
 */
const transparencyClass = computed(() => {
  const classList = []

  if (props.isTransparent) {
    if (props.transparencyPaused) {
      return ''
    }

    classList.push('header--transparent')

    if (props.transparencyColorText)
      classList.push(`header--transparent-${props.transparencyColorText}`)
  }

  return classList.join(' ')
})

const productCounterClass = computed(() => {
  if (props.isTransparent && !props.transparencyPaused) {
    if (props.transparencyColorText === 'black')
      return 'lg:!bg-primitives-black lg:!text-primitives-white'
    if (props.transparencyColorText === 'white')
      return 'lg:!bg-primitives-white lg:!text-primitives-black'
    return ''
  }

  return ''
})

/**
 * Handle is-pinned
 */
const emit = defineEmits<{
  'is-pinned-handler': [boolean]
}>()

const pin = ref()
useIntersectionObserver(
  pin,
  ([{ isIntersecting }]) => {
    if (props.isFederatedApp) return

    emit('is-pinned-handler', !isIntersecting)
  },
  { threshold: 1 }
)

/**
 * Show Megalogo expanded
 */
const isMegalogoExpanded = computed(() => {
  return !props.isPinned && props.hasMegalogo && props.currentBrand !== 'global'
})

const configs = useConfigs()
const isCountryShoppable = computed(() => configs.value.is_shoppable)
</script>

<template>
  <div ref="pin" class="-mt-[1px] h-[1px] opacity-0"></div>
  <header
    ref="headerContainer"
    :class="[
      `header bg-background-secondary text-text-primary top-0 z-50 h-[60px] lg:h-[72px] ${transparencyClass}`,
      staticOnMobile ? 'relative lg:sticky' : 'sticky',
      {
        'header--pinned': isPinned,
        'header--no-transition': freezeAnimation,
        'lg:fixed lg:top-0 lg:w-full': isFederatedApp && isPinned,
      },
    ]"
  >
    <div class="header__container positive-margin relative h-full">
      <div class="header__container-aside hidden items-center lg:flex">
        <HeaderActionContainer class="items-center gap-7">
          <template #default="{ menuStatus, toggleMenu, openSearch }">
            <HeaderActionMenu
              data-testid="open-menu-desktop"
              :is-active="menuStatus.isActive"
              @click-handler="toggleMenu"
            />
            <HeaderActionSearch
              v-if="!isFederatedApp && !menuStatus.isActive"
              @click-handler="openSearch"
            />
          </template>
        </HeaderActionContainer>
      </div>
      <div
        :class="[
          'header__container-logo flex items-center justify-center',
          {
            'header__container-logo--megalogo': hasMegalogo,
          },
        ]"
      >
        <HeaderLogoBrand
          :current-brand="deferredCurrentBrand"
          :megalogo="hasMegalogo"
          :megalogo-expanded="isMegalogoExpanded"
          :freeze-animation="!!freezeAnimation"
        />
      </div>
      <div
        class="header__container-aside hidden items-center justify-end lg:flex"
      >
        <HeaderActionContainer class="items-center gap-7">
          <template
            #default="{
              isShopActive,
              isUserLogged,
              openSupport,
              toggleMyAccount,
            }"
          >
            <HeaderActionSupport @click-handler="openSupport" />
            <HeaderActionAccount
              :is-user-logged="isUserLogged"
              @click-handler="toggleMyAccount"
            />
            <!-- TODO: (new heder) move out the logic from <HeaderActionCart /> -->
            <HeaderActionCart
              v-if="(isShopActive || isFederatedApp) && isCountryShoppable"
              :counter-class="productCounterClass"
            />
          </template>
        </HeaderActionContainer>
      </div>
      <HeaderMenuNav :lists="menuLists" />
    </div>
  </header>
  <div v-if="isTransparent" class="-mt-[60px] lg:-mt-[72px]"></div>
  <div
    :class="{
      hidden: isFederatedApp,
      'lg:block lg:h-[72px]': isFederatedApp && isPinned,
    }"
  ></div>
</template>

<style lang="scss" scoped>
.header {
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  transition-property: background-color, color;
  will-change: background-color, color;

  &:not(:hover) {
    &.header--transparent {
      @apply bg-transparent;

      &-black {
        @apply text-primitives-black;
      }

      &-white {
        @apply text-primitives-white;
      }
    }
  }

  &.header--transparent {
    pointer-events: none;

    @screen lg {
      pointer-events: all;
    }

    &-black {
      .header__container-logo--megalogo {
        @apply text-primitives-black;
      }
    }

    &-white {
      .header__container-logo--megalogo {
        @apply text-primitives-white;
      }
    }
  }

  &__container {
    display: flex;

    &-aside {
      flex-basis: 230px;
      flex-shrink: 0;
    }

    &-logo {
      flex-grow: 1;
    }
  }

  &--no-transition {
    transition-duration: 0s !important;
  }
}
</style>
