<script setup lang="ts">
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'
import type {
  Country,
  FooterAsideLanguageProps,
} from './FooterAsideLanguage.props'
const props = defineProps<FooterAsideLanguageProps>()

const countryNotFoundSidebarId = SIDEBAR_IDS.footerCountryNotFound
const footerAsideLanguageId = SIDEBAR_IDS.footerAsideLanguage
const { isDialogOpen: isNestedSidebarOpen, openDialog: openCountryNotFound } =
  useDialog(countryNotFoundSidebarId)
const { closeDialog: closeDialogFooterLanguage } = useDialog(
  footerAsideLanguageId
)

const { isHome } = useRouteHelper()
const PathWithoutLangCountry = usePathWithoutLangCountry()
const { dispatchActionChooseCountryEvent } = useGAActionEvents()
const continents = computed(() => {
  const continentsMap = new Map<
    string,
    {
      countries: Country[]
    }
  >()

  props.countries.forEach(country => {
    const currentContinent = continentsMap.get(country.continentCode)
    if (currentContinent) {
      currentContinent.countries.push(country)
      return
    }

    continentsMap.set(country.continentCode, {
      countries: [country],
    })
  })

  // Arrange in the following order
  return new Map(
    ['europe', 'america', 'asia', 'oceania', 'africa', 'row'].map(key => [
      key,
      continentsMap.get(key),
    ])
  )
})

const { language: currentLanguage, country: currentCountry } = useRouteHelper()

const isFederatedApp = computed(() => useRuntimeConfig().public.isFederated)

const chooseCountryEvent = (
  countryName: string,
  langCode: string,
  countryCode: string
) => {
  if (isFederatedApp.value) {
    const appConfig = useAppConfig()
    appConfig.currentLanguage = langCode
    appConfig.currentCountry = countryCode
    appConfig.currentLanguageCountry = `${langCode}-${countryCode}`
    // @ts-ignore
    const { eventBus } = useFederatedEventBus()
    eventBus?.$emit('setLocaleEvent', appConfig.currentLanguageCountry)
    closeDialogFooterLanguage()
    return
  }
  dispatchActionChooseCountryEvent(countryName, langCode, 'done')
}

const linksArray = ref<string[]>([])

// Function to get the appropriate URL based on language and country
const url = (language: string, country: Country) => {
  const countryCode = `${language.toLowerCase()}-${country.code.toLowerCase()}`

  if (
    country.isDisabled ||
    (currentCountry === country.code && currentLanguage === language)
  ) {
    return '#'
  } else {
    const foundUrl = linksArray.value.find((url: string) => {
      // Check if the URL contains the country code
      return url.includes(`/${countryCode}/`)
    })

    return (
      country.externalURL ||
      foundUrl ||
      `/${language.toLowerCase()}-${country.code.toLowerCase()}/${
        PathWithoutLangCountry.value
      }`
    )
  }
}

onMounted(() => {
  // Select all <link> elements with rel="alternate" from the document head
  const alternateLinks = document.head.querySelectorAll('link[rel="alternate"]')

  // Convert NodeList to an array and extract the href attribute from each link

  linksArray.value = Array.from(alternateLinks).map(link => {
    const htmlLink = link as HTMLLinkElement
    return htmlLink.href
  })
})
</script>

<template>
  <OrganismsSidebarSlide
    :id="SIDEBAR_IDS.footerAsideLanguage"
    :header-props="{
      titleText: $ts('footer.firstBlock.countrySelector.title'),
    }"
    :force-open="isNestedSidebarOpen"
    :blur-background="isHome"
  >
    <template #body>
      <div class="flex flex-col">
        <div
          v-for="[continentCode, continentData] in continents"
          :key="continentCode"
          class="border-primitives-grey-50 flex flex-col border-t"
        >
          <UIDropdown
            v-if="continentData"
            :title="continentCode"
            title-wrapper="span"
            is-capitalized
          >
            <ul class="gap-md mb-8 mt-2 flex flex-col">
              <li
                v-for="country in continentData.countries"
                :key="country.code"
                class="text-book-strike-6 flex items-center justify-between"
              >
                <div class="gap-xs flex items-center">
                  <DefaultIconsCart
                    aria-hidden="true"
                    class="size-5 flex-shrink-0"
                  />
                  <p>
                    {{ country.name }}
                    <span
                      v-if="country?.cl?.currencySymbol"
                      class="text-light-7 text-text-tertiary"
                    >
                      {{ `(${country.cl.currencySymbol})` }}
                    </span>
                  </p>
                  <DefaultIconsGo
                    v-if="country.externalURL"
                    class="!text-primitives-grey-200"
                    aria-hidden="true"
                  />
                </div>
                <div class="gap-xxs text-book-6 flex">
                  <div
                    v-for="language in country.languages"
                    :key="language.langcode"
                    class="flex gap-4"
                  >
                    <MoleculesLinkWrapper
                      anatomy="secondary"
                      size="s"
                      class="uppercase"
                      :icon-show-default="false"
                      :aria-label="
                        $ts(`accessibility.goToCountryWithLanguage`, {
                          country: country.name,
                          language: language.langcode,
                        })
                      "
                      :external="true"
                      :to="url(language.langcode, country)"
                      :target="country.externalURL ? '_blank' : '_self'"
                      :type="isFederatedApp ? 'button' : 'a'"
                      @click="
                        chooseCountryEvent(
                          country.name,
                          language.langcode,
                          country.code
                        )
                      "
                    >
                      <template #label>
                        {{ language.langcode }}
                      </template>
                    </MoleculesLinkWrapper>
                  </div>
                </div>
              </li>
            </ul>
          </UIDropdown>
        </div>

        <div class="my-md text-light-6">
          <span class="not-sr-only" aria-hidden="true">
            {{ `${$ts('footer.firstBlock.locationNotFound')} ` }}
          </span>
          <AtomsCta
            anatomy="link"
            aria-haspopup="dialog"
            :aria-label="`${$ts('footer.firstBlock.locationNotFound')} ${$ts('footer.firstBlock.clickHere')}`"
            @click="openCountryNotFound()"
            ><template #label>
              {{ $ts('footer.firstBlock.clickHere') }}
            </template>
          </AtomsCta>
        </div>
      </div>
      <FooterCountryNotFound />
    </template>
  </OrganismsSidebarSlide>
</template>
