export async function useFooter(deliveryKey: string) {
  const localePath = useLocalePath()

  const query = {
    locale: localePath(''),
    vse: useRoute().query.vse,
    contentId: useRoute().query.contentId,
    deliveryKey: deliveryKey,
  }

  const { data, refresh } = await useFetch('/api/cms/getFooterLinks', {
    query,
  })

  return {
    data,
    refresh,
  }
}
